
import { MdmPolicySchema } from '@/generated/sdk'
import { Vue } from 'vue-property-decorator'

export default class PolicyEdit extends Vue {

  get parents(): MdmPolicySchema[] {
    return this.getParents(this.policy).reverse()
  }

  get policy(): MdmPolicySchema {
    const policy =  this.policies.find(
      m => m.id === this.$route.params.id,
    )
    this.$store.commit('setCurrentPolicy', policy)
    return policy
  }

  get policies(): MdmPolicySchema[] {
    return this.$store.state.policies.mdmPolicies
  }

  getParents(mdmPolicy: MdmPolicySchema): MdmPolicySchema[] {
    const parent = this.getParent(mdmPolicy)
    if (parent) {
      return [parent].concat(this.getParents(parent))
    } else {
      return []
    }
  }

  getParent(mdmPolicy: MdmPolicySchema): MdmPolicySchema {
    return this.policies.find(m => m.id === mdmPolicy.parent?.id)
  }
}
