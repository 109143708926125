import { MdmPolicySchema, PolicySchema } from '@/generated/sdk'
import { isObject } from 'lodash'

export function formatPolicy(policy?: PolicySchema): string {
  return JSON.stringify(trim(policy), null, 2)
}

export function trim(object?: any) {
  if (isObject(object) && !Array.isArray(object)) {
    const keys = Object.keys(object)
    const result = {}
    for (const key of keys) {
      const trimmed = trim(object[key])
      if (trimmed !== null) {
        result[key] = trimmed
      }
    }
    return result
  } else if (Array.isArray(object)) {
    return object.map(o => trim(o)).filter(o => o !== null)
  } else {
    return object
  }
}

export function merge(p: MdmPolicySchema, policies: MdmPolicySchema[]): PolicySchema {
  const mergedParent: Partial<PolicySchema> = p.parent?.id
    ? merge(
      policies.find(parent => parent.id === p.parent?.id),
      policies,
    )
    : {
      statusReportingSettings: {
        networkInfoEnabled: true,
      },
      playStoreMode: null,
      deviceOwnerLockScreenInfo: null,
      kioskCustomization: null,
      applications: null,
      setupActions: null,
      advancedSecurityOverrides: null,
      openNetworkConfiguration: null,
      screenCaptureDisabled: null,
      factoryResetDisabled: null,
      cameraAccess: null,
      microphoneAccess: null,
      networkEscapeHatchEnabled: null,
      kioskCustomLauncherEnabled: null,
      setWallpaperDisabled: null,
      safeBootDisabled: null,
      frpAdminEmails: null,
    }

  // Merge applications
  const parentApplications = mergedParent.applications || []
  const ownApplications = (p.configPolicy.applications || []).map(a => ({
    packageName: a.packageName,
    installType: a.installType,
    defaultPermissionPolicy: a.defaultPermissionPolicy || null,
    autoUpdateMode: a.autoUpdateMode || null,
    delegatedScopes: a.delegatedScopes || null,
    disabled: a.disabled || null,
    permissionGrants: a.permissionGrants || null,
    managedConfigurationTemplate: a.managedConfigurationTemplate || null,
  }))
  
  const applications =
    parentApplications.length + ownApplications.length === 0
      ? null
      : parentApplications.concat(ownApplications)

  return {
    id: p.policy.id,
    statusReportingSettings:
      p.configPolicy.statusReportingSettings ||
      mergedParent.statusReportingSettings,
    playStoreMode: p.configPolicy.playStoreMode || mergedParent.playStoreMode,
    deviceOwnerLockScreenInfo:
      p.configPolicy.deviceOwnerLockScreenInfo ||
      mergedParent.deviceOwnerLockScreenInfo,
    kioskCustomization:
      p.configPolicy.kioskCustomization || mergedParent.kioskCustomization,
    applications,
    setupActions: p.configPolicy.setupActions || mergedParent.setupActions,
    advancedSecurityOverrides:
      p.configPolicy.advancedSecurityOverrides ||
      mergedParent.advancedSecurityOverrides,
    openNetworkConfiguration:
      p.configPolicy.openNetworkConfiguration ||
      mergedParent.openNetworkConfiguration,
    screenCaptureDisabled:
      p.configPolicy.screenCaptureDisabled ||
      mergedParent.screenCaptureDisabled,
    factoryResetDisabled:
      p.configPolicy.factoryResetDisabled || mergedParent.factoryResetDisabled,
    cameraAccess: p.configPolicy.cameraAccess || mergedParent.cameraAccess,
    microphoneAccess:
      p.configPolicy.microphoneAccess || mergedParent.microphoneAccess,
    networkEscapeHatchEnabled:
      p.configPolicy.networkEscapeHatchEnabled ||
      mergedParent.networkEscapeHatchEnabled,
    kioskCustomLauncherEnabled:
      p.configPolicy.kioskCustomLauncherEnabled ||
      mergedParent.kioskCustomLauncherEnabled,
    setWallpaperDisabled:
      p.configPolicy.setWallpaperDisabled || mergedParent.setWallpaperDisabled,
    safeBootDisabled:
      p.configPolicy.safeBootDisabled || mergedParent.safeBootDisabled,
    frpAdminEmails:
      p.configPolicy.frpAdminEmails || mergedParent.frpAdminEmails,
  } as PolicySchema
}

export async function getPoliciesToUpdate(
  remotePolicies: PolicySchema[],
  localPolicies: PolicySchema[],
): Promise<PolicySchema[]> {
  const policiesToUpdate: PolicySchema[] = []

  // Sort applications, so the diff makes sense
  for (const p of [...remotePolicies, ...localPolicies]) {
    (p.applications || []).sort((a, b) =>
      a.packageName.localeCompare(b.packageName),
    )
  }

  // Search for diffs by serializing to JSON
  for (const localPolicy of localPolicies) {
    const remotePolicy = remotePolicies.find(p => p.id === localPolicy.id)

    if (!remotePolicy) {
      console.warn('No policy found for: ' + localPolicy.name)
    }

    const local = JSON.stringify(localPolicy, null, 2)
    const remote = JSON.stringify(remotePolicy, null, 2)

    if (local !== remote) {
      policiesToUpdate.push(localPolicy)

      console.log(`------ Syncing: ${localPolicy.id}`)

      const localLines = local.split('\n')
      const remoteLines = remote.split('\n')

      for (let i = 0; i < localLines.length; i++) {
        const l = localLines[i]
        const r = remoteLines[i]

        if (l !== r) {
          console.log(`local  : ${l}`)
          console.log(`remote : ${r}`)
        }
      }

      console.log('------')
    }
  }

  return policiesToUpdate
}