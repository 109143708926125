
import { MdmDeviceSchema, MdmPolicySchema } from '@/generated/sdk'
import { Vue, Watch } from 'vue-property-decorator'
import { format } from 'date-fns'

export default class Devices extends Vue {

  policyFilter: string = null
  expectedPolicyFilter: string = null
  filter = ''
  filterFieldOptions = ['IMEI', 'Serial']
  filterField = this.filterFieldOptions[0]
  page = 1
  size = 10

  get totalRecords(): number {
    return this.$store.state.app.stats.countMdmDevice
  }

  get mdmPolicies(): MdmPolicySchema[] {
    return this.$store.state.policies.mdmPolicies
  }

  get mdmDevices(): MdmDeviceSchema[] {
    return this.$store.state.devices.mdmDevices
  }

  @Watch('policyFilter')
  @Watch('expectedPolicyFilter')
  @Watch('filter')
  @Watch('filterField')
  async watchFilter() {
    await this.loadMdmDevices()
  }

  async mounted(): Promise<void> {
    await this.loadMdmDevices()
  }

  async onPage(event): Promise<void> {
    this.page = event.page + 1
    this.size = event.rows
    await this.loadMdmDevices()
  }

  async loadMdmDevices(): Promise<void> {
    const val = this.filter.trim() || undefined
    await this.$store.dispatch('loadMdmDevices', { 
      input: {
        imei: this.filterField === 'IMEI' ? val : undefined,
        serial: this.filterField !== 'IMEI' ? val : undefined,
        policyName: this.formatId(this.policyFilter) || undefined, // Hacky way to match only on id
        expectedPolicyName: this.formatId(this.expectedPolicyFilter) || undefined, // Hacky way to match only on id
        enterpriseId: this.$store.state.app.enterprise.id,
      }, 
      queryArgs: { 
        page: this.page, 
        size: val ? this.size + 10 : this.size,  // Hacky way to get all results, 
      },
    })
  }

  async updateMdmDevicePolicy(e: MdmDeviceSchema) {
    if (window.confirm(`Update device with IMEI: ${e.imei} to "${this.formatPolicyName(e.policyName)}"?`)) {
      await this.$store.dispatch('updateMdmDevices', [{
        id: e.id,
        googleId: e.googleId,
        policyName: e.policyName,
      } as MdmDeviceSchema])
    }
  }

  async deleteDevice(e: MdmDeviceSchema) {
    if (window.confirm(`WARNING: Do you want to delete device with IMEI: ${e.imei}?`)) {
      await this.$store.dispatch('deleteMdmDevice', e)
    }
  }

  formatId(id: string): string {
    return id?.split('/')[3]
  }

  formatPolicyName(id: string): string {
    return this.mdmPolicies.find(m => m.googleId === id)?.name
  }

  formatDate(date: string): string {
    return date ? format(new Date(date), 'dd-MM-yy HH:mm:ss') : undefined
  }
}
