
import { SwitchDeviceArgs, SwitchDeviceSchema } from '@/generated/sdk'
import { Vue } from 'vue-property-decorator'
import { formatStringDate } from '../utils'

export default class SwitchDevices extends Vue {

  get switches(): SwitchDeviceSchema[] {
    return this.$store.state.devices.switches
  }

  async mounted(): Promise<void> {
    await this.loadSwitches({ page: 1, size: 10 })
  }

  async onPage(event): Promise<void> {
    await this.loadSwitches({ page: event.page + 1, size: event.rows })
  }

  async loadSwitches(queryArgs: SwitchDeviceArgs): Promise<void> {
    await this.$store.dispatch('loadSwitches', queryArgs)
  }

  formatId(id: string): string {
    return id.split('/')[3]
  }

  formatPolicyName(id: string): string {
    return this.$store.state.policies.mdmPolicies.find(m => m.googleId === id).name
  }

  formatDate = formatStringDate
}
