import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mdm-white-panel mdm-overflow-x" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QrCode = _resolveComponent("QrCode")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.enterprise.enterpriseDisplayName) + " (" + _toDisplayString(_ctx.enterprise.name) + ")", 1),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_QrCode)
    ])
  ]))
}