import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from './views/Home.vue'
import Policies from './views/Policies.vue'
import PolicyEdit from './views/PolicyEdit.vue'
import BulkPolicyEdit from './views/BulkPolicyEdit.vue'
import ZeroTouch from './views/ZeroTouch.vue'
import GoogleRedirect from './views/GoogleRedirect.vue'
import Enterprise from './views/Enterprise.vue'
import Login from './views/Login.vue'
import Devices from './views/Devices.vue'
import SwitchDevices from './views/SwitchDevices.vue'
import { authGuard } from './auth-guard'

const auth = { beforeEnter: authGuard }

const r: Array<RouteRecordRaw> = [
  { ...auth, path: '/', name: 'Home', component: Home as any },
  { ...auth, path: '/policies', name: 'Policies', component: Policies as any },
  { ...auth, path: '/policies/bulk', name: 'Bulk Policy Edit', component: BulkPolicyEdit as any },
  { ...auth, path: '/policies/:id', name: 'Policy Edit', component: PolicyEdit as any },
  { ...auth, path: '/devices', name: 'Devices', component: Devices as any },
  { ...auth, path: '/switch-devices', name: 'SwitchDevices', component: SwitchDevices as any },
  { ...auth, path: '/zero-touch', name: 'Zero touch', component: ZeroTouch as any },
  { ...auth, path: '/google-redirect', name: 'Google Redirect', component: GoogleRedirect as any },
  { ...auth, path: '/enterprise', name: 'Enterprise', component: Enterprise as any },

  { path: '/login', name: 'Login', component: Login as any, meta: { hideNavBar: true } },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: r,
})

export default router
