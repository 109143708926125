
import { Vue } from 'vue-property-decorator'

export default class Login extends Vue {

  apiKey: string = ''

  login(): void {
    this.$store.commit('setApiKey', this.apiKey)
    this.$router.push('/')
  }
}
