import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "container"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isHttps)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "Https (SSL) is required to load Zero-touch portal"))
}