
import { MdmPolicySchema, StatsQuery } from '@/generated/sdk'
import { Vue } from 'vue-property-decorator'

export default class Home extends Vue {

  get stats(): StatsQuery {
    return this.$store.state.app.stats
  }

  get policyTotal(): { name: string, active: number, total: number }[] {
    return this.stats.countPerPolicy
      .map(p => {
        const active = this.stats.countMdmDeviceByPolicyByDate.find(c => c.group === p.group)?.count || 0
        return {
          name: this.formatPolicyName(p.group) || '(No policy)',
          active,
          total: p.count,
        }},
      )
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  get mdmPolicies(): MdmPolicySchema[] {
    return this.$store.state.policies.mdmPolicies
  }
  
  formatPolicyName(id: string): string {
    return this.mdmPolicies.find(m => m.googleId === id)?.name
  }
}
