import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeColumn = _resolveComponent("PrimeColumn")!
  const _component_CheckMark = _resolveComponent("CheckMark")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_ctx.switches)
    ? (_openBlock(), _createBlock(_component_DataTable, {
        key: 0,
        lazy: true,
        "total-records": 100000,
        value: _ctx.switches,
        "data-key": "id",
        "responsive-layout": "scroll",
        paginator: true,
        rows: 10,
        "paginator-template": "CurrentPageReport PrevPageLink NextPageLink RowsPerPageDropdown",
        "rows-per-page-options": [10,50,100],
        "current-page-report-template": "Showing {first} to {last}",
        onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PrimeColumn, {
            field: "device.id",
            header: "ID"
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.device ? _ctx.formatId(data.device.id) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeColumn, {
            field: "device.lastPolicySyncTime",
            header: "Last policy sync time"
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.device ? _ctx.formatDate(data.device.lastPolicySyncTime) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeColumn, {
            field: "device.lastStatusReportTime",
            header: "Last status report time"
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.device ? _ctx.formatDate(data.device.lastStatusReportTime) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeColumn, {
            field: "device.hardwareInfo.serialNumber",
            header: "Serial number"
          }),
          _createVNode(_component_PrimeColumn, {
            field: "imei",
            header: "IMEI"
          }),
          _createVNode(_component_PrimeColumn, {
            field: "kioskPolicy",
            header: "Kiosk policy"
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(_ctx.formatPolicyName(data.kioskPolicy)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeColumn, {
            field: "normalPolicy",
            header: "Normal policy"
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(_ctx.formatPolicyName(data.normalPolicy)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_PrimeColumn, {
            field: "device.policyName",
            header: "On normal policy"
          }, {
            body: _withCtx(({data}) => [
              _createVNode(_component_CheckMark, {
                checked: data.normalPolicy === data.device?.policyName
              }, null, 8, ["checked"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]))
    : _createCommentVNode("", true)
}