
import { MdmPolicySchema, PolicyPlayStoreMode, PolicySchema } from '@/generated/sdk'
import { Vue } from 'vue-property-decorator'

export default class Policies extends Vue {

  sortField = 'name'
  sortOrder = 1
  filter = ''
  showDialog = false
  creationLoading = false
  newPolicyName = ''

  get mdmPolicies(): MdmPolicySchema[] {
    return this.$store.state.policies.mdmPolicies
      ?.sort((a,b) => {
        let result = 0
        if(this.sortField === 'nApps') {
          result = (a.policy?.applications?.length || 0) - (b.policy?.applications?.length || 0)
        } else if(this.sortField === 'kioskMode') {
          result = (a.policy?.kioskCustomization ? 1 : 0) - (b.policy?.kioskCustomization ? 1 : 0)
        } else if(this.sortField === 'openPlayStore') {
          result = (this.hasOpenPlayStore(a.policy) ? 1 : 0) - (this.hasOpenPlayStore(b.policy) ? 1 : 0)
        } else {
          // Sort by name is default
          result = a.name.localeCompare(b.name)  
        }
        return this.sortOrder * result      
      })
      .filter(p => {
        if(!this.filter.trim()) return true

        return p.name.toLowerCase().includes(this.filter.trim().toLowerCase())
      })
  }

  mounted(): void {
    this.$store.dispatch('loadMdmPolicies')
  }

  rowClick(event) {
    this.$router.push(`/policies/${event.data.id}`)
  }

  sort(event) {
    this.sortField = event.sortField
    this.sortOrder = event.sortOrder
  }

  getParentName(policy: MdmPolicySchema) {
    return this.mdmPolicies.find(p => policy.parent?.id === p.id)?.name || '-'
  }

  hasOpenPlayStore(policy?: PolicySchema) {
    return policy?.playStoreMode === PolicyPlayStoreMode.Blacklist
  }

  async createPolicy() {
    this.showDialog = false
    this.creationLoading = true

    try {
      await this.$store.dispatch('createPolicy', this.newPolicyName)
      this.newPolicyName = ''
    } finally {
      this.creationLoading = false
    }
  }
}
