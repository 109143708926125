import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f506644e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mdm-l-router-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MdmNavBar = _resolveComponent("MdmNavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PrimeToast = _resolveComponent("PrimeToast")!
  const _component_DodoRow = _resolveComponent("DodoRow")!

  return (_openBlock(), _createBlock(_component_DodoRow, {
    gap: "0",
    class: "dodo-fonts"
  }, {
    default: _withCtx(() => [
      (_ctx.showNavBar)
        ? (_openBlock(), _createBlock(_component_MdmNavBar, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath
        }))
      ]),
      _createVNode(_component_PrimeToast)
    ]),
    _: 1
  }))
}