
import { Vue } from 'vue-property-decorator'
declare const gapi: any

export default class ZeroTouch extends Vue {

  get isHttps(): boolean {
    return location.protocol === 'https:'
  }

  async created(): Promise<void> {
    if (!this.isHttps) return

    const { value } = await this.$store.dispatch('createWebToken')
    const url = `https://enterprise.google.com/android/zero-touch/embedded/companyhome?token=${value}&dpcId=com.google.android.apps.work.clouddpc`

    gapi.load('gapi.iframes', () => {
      const options = {
        url,
        where: this.$refs.container,
        attributes: { style: 'width: 1000px; height:1000px', scrolling: 'yes' },
      }
      gapi.iframes.getContext().openChild(options)
    })
  }
}
