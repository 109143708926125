
import { MdmDeviceSchema, MdmPolicySchema, MdmDeviceInputSchema } from '@/generated/sdk'
import { Vue } from 'vue-property-decorator'
import * as Papa from 'papaparse'

// The raw csv row
interface CSVEntry {
  rowNumber: number
  type: 'imei' | 'serial'
  reference: string
  policy: string
}

// Possible result states after processing rows
enum CSVPreviewState {
  DeviceNotFound,
  MultipleDevicesFound,
  PolicyNotFound,
  DevicePolicyNotFound,
  IncorrectPolicy,
  InvalidRow,
  OK,
}

// Result after processing the rows (before actual execution)
interface CSVPreviewEntry {
  rowNumber: number
  entry: CSVEntry
  message: string
  matchedDevices?: MdmDeviceSchema[]
  matchedPolicy?: MdmPolicySchema
  devicePolicy?: MdmPolicySchema
  state: CSVPreviewState
}

export default class BulkPolicyEdit extends Vue {

  previewEntries: CSVPreviewEntry[] = null
  error: string = null

  get mdmPolicies(): MdmPolicySchema[] {
    return this.$store.state.policies.mdmPolicies
  }

  get mdmDevices(): MdmDeviceSchema[] {
    return this.$store.state.devices.mdmDevices
  }

  upload({ files }: { files: File[] }) {
    for (const file of files) {
      Papa.parse<CSVEntry>(file, {
        header: true,
        error: (err, file) =>
        {
          console.error(err)
          this.error = err.message
        },
        complete: (parsed) =>
        {
          this.handleCSV(parsed.data)
            .then((res) => this.previewEntries = res)
            .catch((e) => console.error('Error handling CSV:', e))
        },
      })
    }
  }

  async handleCSV(entries: CSVEntry[]): Promise<CSVPreviewEntry[]> {
    
    const search = entries
      .filter(e => this.isValidEntry(e))
      .map(e =>
        ({ 
          [e.type]: e.reference, 
          enterpriseId: this.$store.state.app.enterprise.id,
        }),
      )

    await this.$store.dispatch('loadMdmDevices', { 
      input: search,
      queryArgs: { 
        size: 10000, 
        page: 1,
      },
    })

    return entries.map((e, index) => this.toPreview(e, index))
  }

  toPreview(entry: CSVEntry, index: number): CSVPreviewEntry {

      const matchedPolicy = this.mdmPolicies.find(p => p.name === entry.policy || p.id === entry.policy)
      const rowNumber = index + 1

      if (!this.isValidEntry(entry)){
        return {
          rowNumber,
          entry,
          state: CSVPreviewState.InvalidRow,
          message: 'Invalid row',
        }
      }

      if (!matchedPolicy) {
        return {
          rowNumber,
          entry,
          state: CSVPreviewState.PolicyNotFound,
          message: `Policy not found (${entry.policy})`,
        }
      }
      
      const matchedDevices = this.mdmDevices.filter(d =>
        d[entry.type] === entry.reference,
      )

      if (matchedDevices.length === 0) {
        return {
          rowNumber,
          entry,
          matchedDevices,
          matchedPolicy,
          state: CSVPreviewState.DeviceNotFound,
          message: `Device not found (${entry.type}: "${entry.reference}")`,
        }
      }

      if (matchedDevices.length > 1) {
        return {
          rowNumber,
          entry,
          matchedDevices,
          matchedPolicy,
          state: CSVPreviewState.MultipleDevicesFound,
          message: `Multiple devices found (${matchedDevices.map(d => this.formatId(d.googleId)).join(' - ')})`,
        }
      }

      const devicePolicy = this.mdmPolicies.find(p => p.googleId === matchedDevices[0].policyName)

      if (!devicePolicy) {
        return {
          rowNumber,
          entry,
          matchedDevices,
          matchedPolicy,
          state: CSVPreviewState.DevicePolicyNotFound,
          message: `Device policy not found (${matchedDevices[0].policyName})`,
        }
      }

      if (devicePolicy.id !== matchedPolicy.id) {
        return {
          rowNumber,
          entry,
          matchedDevices,
          matchedPolicy,
          devicePolicy,
          state: CSVPreviewState.IncorrectPolicy,
          message: `Incorrect policy (expected: "${matchedPolicy.name}" got: "${devicePolicy.name}")`,
        }
      }

      return {
        rowNumber,
        entry,
        matchedDevices,
        matchedPolicy,
        devicePolicy,
        state: CSVPreviewState.OK,
        message:'OK',
      }
  }

  async continueProcessing(){
    const toUpdate = this.previewEntries
      .filter(p => p.state === CSVPreviewState.IncorrectPolicy)
      .map(t => ({
        id: t.matchedDevices[0].id,
        googleId: t.matchedDevices[0].googleId,
        policyName: t.matchedPolicy.googleId,
      } as MdmDeviceSchema))

    if(toUpdate.length === 0){
      window.alert('No devices will be updated directly')
    } else if (window.confirm(`Are you sure you want to directly update the policy of ${toUpdate.length} devices?`)) {
      await this.$store.dispatch('updateMdmDevices', toUpdate)
    } else {
      return
    }

    const toInsert = this.previewEntries
      .filter(p => p.state === CSVPreviewState.DeviceNotFound)
      .map(t => ({
        enterpriseId: this.$store.state.app.enterprise.id,
        expectedPolicyName: t.matchedPolicy.googleId,
        imei: t.entry.type === 'imei' ? t.entry.reference : undefined,
        serial: t.entry.type === 'serial' ? t.entry.reference : undefined,
      } as MdmDeviceInputSchema))


    if(toInsert.length === 0){
      window.alert('No devices will preloaded')
    } else if (window.confirm(`Are you sure you want to preload the policy of ${toInsert.length} devices?`)) {
      await this.$store.dispatch('createMdmDevices', toInsert)
    } else {
      return
    }
      
    this.previewEntries = null
  }

  rowClass(data: CSVPreviewEntry) {
    return {
      [CSVPreviewState.OK] : 'mdm-success',
      [CSVPreviewState.IncorrectPolicy] : 'mdm-warn',
    }[data.state] || 'mdm-danger'
  }

  formatId(id: string): string {
    return id.split('/')[3]
  }

  isValidEntry(e: CSVEntry) {
    return ['imei', 'serial'].includes(e.type.toLowerCase()) && e.reference
  }
}
