
import 'reflect-metadata'
import { Vue } from 'vue-property-decorator'

export default class App extends Vue {
  get showNavBar(): boolean {
    return !this.$router.currentRoute.value.meta?.hideNavBar
  }

  mounted(): void {
    // Load the google api
    const apiScript = document.createElement('script')
    apiScript.setAttribute('src', 'https://apis.google.com/js/api.js')
    document.head.appendChild(apiScript)
  }
}
