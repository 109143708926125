import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store/index'

// PrimeVue
import PrimeVue from 'primevue/config'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import FileUpload from 'primevue/fileupload'
import Textarea from 'primevue/textarea'
import Panel from 'primevue/panel'
import InputSwitch from 'primevue/inputswitch'
import MultiSelect from 'primevue/multiselect'
import Skeleton from 'primevue/skeleton'
import Toast from 'primevue/toast'

// Dodo UI
import { Button, NavBar, NavBarItem, Row, Icon, Dialog, Tabs, SelectButtons, Column as DodoColumn } from '@madxnl/dodo-ui'

// Our styling
import '@/scss/index.scss'

// PrimeVue styling
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import ToastService from 'primevue/toastservice'

// Dodo UI styling
import '@madxnl/dodo-ui/dist/style.css'

const app = createApp(App as any)

// Make our components available anywhere
const requireComponent = require.context('./', true, /[A-Z]\w+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const match = fileName.match(/(\w+)\.\w+$/)
  if (match) app.component(match[1], requireComponent(fileName).default)
})

app.use(router)
app.use(store)
app.use(ToastService)
app.use(PrimeVue)

// PrimeVue
app.component('InputText', InputText)
app.component('PrimeDropdown', Dropdown)
app.component('MultiSelect', MultiSelect)
app.component('DataTable', DataTable)
app.component('PrimeColumn', Column)
app.component('PrimeSplitter', Splitter)
app.component('SplitterPanel', SplitterPanel)
app.component('FileUpload', FileUpload)
app.component('PrimeTextarea', Textarea)
app.component('PrimePanel', Panel)
app.component('InputSwitch', InputSwitch)
app.component('PrimeSkeleton', Skeleton)
app.component('PrimeToast', Toast)

// Dodo UI
app.component('DodoRow', Row)
app.component('DodoButton', Button)
app.component('NavBar', NavBar)
app.component('NavBarItem', NavBarItem)
app.component('DodoIcon', Icon)
app.component('DodoDialog', Dialog)
app.component('DodoTabs', Tabs)
app.component('SelectButtons', SelectButtons)
app.component('DodoColumn', DodoColumn)

app.mount('#app')
