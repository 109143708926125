
import { EnterpriseSchema } from '@/generated/sdk'
import { Vue } from 'vue-property-decorator'

export default class Enterprise extends Vue {

  get enterprise(): EnterpriseSchema {
    return this.$store.state.app.enterprise
  }
}
