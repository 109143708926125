
import { Vue } from 'vue-property-decorator'

export default class GoogleRedirect extends Vue {

  async created(): Promise<void> {
    await this.$store.dispatch('tempSetToken', this.$route.query.code)
    this.$router.push(localStorage.getItem('returnPath') || '/')
  }
}
